<template>
  <div class='indexbox'>
    <div class="searchbox">
      <div class="left">
        <el-input class="inp" size='small' v-model="keywords" placeholder="Type Text To Search" clearable
          style="width: 200px" @change='pagechage(1)'></el-input>
        <el-button type="primary"
          style="background: #fff !important;border-radius: 4px;border-color: rgba(220,223,230,1) !important; color:#b4bccc !important;">search</el-button>
      </div>
      <div class="right">
        <!-- <el-button @click="BatchAddFun()" v-hasPermi="['ccfe']" class="download" type="primary"
          icon="el-icon-download">Batch Add</el-button> -->
        <el-button v-hasPermi="['ccfe']" type="primary" @click="change(1)">Add</el-button>
        <!-- <el-button v-hasPermi="['jac']" type="primary" @click="$router.push('formImport')">Import</el-button> -->
      </div>
    </div>

    <div class="tablebox">
      <div class="draggable" v-loading='loading'>
        <el-table :data="tableData" stripe max-height='700px'>
          <el-table-column prop="title" label="Field Name" width='250' align='center' fixed />
          <el-table-column prop="is_required" label="is Required" min-width='100' align='center' show-overflow-tooltip>
            <template slot-scope="scope">
              <p>{{ scope.row.is_required == 0 ? 'No' : scope.row.is_required == 1 ? 'Yes' : '' }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="jira_type" label="Jira Field" min-width='200' align='center' show-overflow-tooltip />
          <el-table-column prop="remark" label="Remark" min-width='200' align='center' show-overflow-tooltip />
          <el-table-column label="" width="190">
            <template slot-scope="scope">
              <el-button v-hasPermi="['ccfe']" size="small" @click="change(2, scope.row)"
                style="margin: 0 15px;">Edit</el-button>
              <el-popconfirm title="Are you sure you want to delete it?" @confirm="delchange(scope.row)"
                v-if="scope.row.id > 6">
                <el-button v-hasPermi="['ccfe']" slot="reference" type="danger" size="small">delete</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination @current-change='pagechage' @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]"
        :page-size="page_size" background layout="total,sizes,prev, pager, next" v-show="total > 0"
        :current-page.sync="page" :total="total" />
    </div>

    <el-dialog :title="type == 1 ? 'Add' : 'Edit'" :visible.sync="show" append-to-body @close="close">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="Field Name" prop="title">
          <el-input v-model="form.title" clearable placeholder="Please Enter"></el-input>
        </el-form-item>
        <el-form-item label="is Required" required>
          <el-switch v-model="form.is_required" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="Jira Type" required prop="jira_type_id">
          <el-select style="width: 100%;" v-model="form.jira_type_id" filterable placeholder="Please Select"
            value-key="id">
            <el-option v-for="item in list" :key="item.jira_type" :label="item.jira_type" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Remark">
          <el-input v-model="form.remark" clearable placeholder="Enter Remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">cancel</el-button>
        <el-button type="primary" :loading="loadingb" @click="onSubmit">Save</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Batch Add" :visible.sync="BatchAddShow" append-to-body @close="BatchAddClose">
      <div class="bodybox">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 1</p>
              <p>Download Our Allocate List Template File</p>
            </div>
          </div>
          <el-button class="download" @click="BatchAddDownload" :loading='btn_loading'>Download Excel Template</el-button>
        </div>
      </div>
      <div class="bodybox" style="border:none;">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 2</p>
              <p>Import The Updated Template File</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bodybox" style="border:none;padding:0;">
        <div class="upfile">
          <el-form ref="form2" :model="form2" :rules="rules2" class="formbox">
            <el-form-item prop="file">
              <el-upload :limit='1' class="upload-demo" action='' drag :auto-upload="false" accept=".xlsx, .xls"
                :on-change="Uploadchange" :on-remove='clearFiles' :file-list="fileList">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drag The File Here, Or <em>Click Upload</em></div>
                <div style="color:red" slot="tip">Tip: Only Import Is Allowed “xls” Or “xlsx” Format File！</div>
              </el-upload>
            </el-form-item>
            <!-- <el-form-item class="btnbox">
              <el-button type="primary" @click.native.prevent="loadOrders" :loading='btn_loading' v-if="!show">Verify
                File</el-button>
              <el-button class="download" type="primary" :loading='btn_loadinga' v-if="show" @click="importOrders">Mass
                Allocate</el-button>
            </el-form-item> -->
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="BatchAddClose">cancel</el-button>
        <el-button type="primary" :loading="loadingb" @click="BatchAddSave">Save</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      list: [],
      keywords: this.keywords,
      total: 0,
      page: 1,
      page_size: 10,
      tableData: [],
      loading: false,
      type: 1,//1add 2edit
      show: false,
      BatchAddShow: false,
      fileList: [],
      form2: {
        file: '',
      },
      rules2: {
        file: [
          { required: true, trigger: "blur", message: 'Please Upload The File' }
        ]
      },
      btn_loading: false,
      form: {
        title: '',
        jira_type_id: '',
        is_required: 0,
        remark: '',
      },
      rules: {
        title: [
          { required: true, message: 'Please Enter', trigger: 'blur' }
        ],
        jira_type_id: [
          { required: true, message: 'Please Select', trigger: 'blur' }
        ],
      },
      loadingb: false
    };
  },
  computed: {
    ...mapGetters(['api_id']),
  },
  methods: {
    getconfig() {
      if (!this.api_id) return ''
      this.$post({
        url: "/app/custom_field/getJiraTypeList",
        data: {
          api_id: this.api_id
        },
        success: (res) => {
          this.list = res.data
        },
        tip: () => {
        },
      })
    },
    getlist() {
      if (!this.api_id) return;
      this.loading = true
      this.$post({
        url: "/app/header_field/pageList",
        data: {
          page: this.page,
          page_size: this.page_size,
          title: this.keywords,
          api_id: this.$store.state.user.api_id
        },
        success: (res) => {
          this.page = res.data.current_page
          this.page_size = Number(res.data.per_page)
          this.tableData = res.data.data
          this.total = res.data.total
          setTimeout(() => {
            this.loading = false
          }, 200);
        },
        tip: () => {
          this.loading = false
        },
      })
    },
    pagechage(page) {
      this.page = page
      this.tableData = []
      this.getlist()
    },
    handleSizeChange(size) {
      this.page_size = size
      this.tableData = []
      this.getlist()
    },
    change(type, data) {
      this.type = type
      if (type == 2) {
        this.form = {
          id: data.id,
          title: data.title,
          jira_type_id: Number(data.jira_type_id),
          is_required: data.is_required,
          remark: data.remark,
        }
      }
      this.show = true
    },
    close() {
      this.form = {
        title: '',
        jira_type_id: '',
        is_required: 0,
        remark: '',
      }
      this.show = false
      this.$refs.form.resetFields()
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingb = true
          let url = '', data = {}
          if (this.type == 1) {
            url = "/app/header_field/add"
            data = {
              api_id: this.api_id,
              ...this.form,
            }
          } else {
            url = "/app/header_field/edit"
            data = {
              api_id: this.api_id,
              id: this.form.id,
              title: this.form.title,
              is_required: this.form.is_required,
              jira_type_id: this.form.jira_type_id,
              remark: this.form.remark,
            }
          }
          this.$post({
            url,
            data,
            success: (res) => {
              this.loadingb = false
              this.show = false
              this.pagechage(1)
            },
            tip: () => {
              this.loadingb = false
            },
          })
        }
      })
    },
    delchange(data) {
      this.loading = true
      this.$post({
        url: "/app/header_field/del",
        data: { id: data.id, api_id: this.api_id },
        success: (res) => {
          this.pagechage(1)
        },
        tip: () => {
          this.loading = false
        },
      })
    },
    BatchAddFun() {
      this.clearFiles()
      this.BatchAddShow = true
    },
    BatchAddClose() {
      this.clearFiles()
      this.BatchAddShow = false
    },
    //导入模板下载
    BatchAddDownload() {
      this.btn_loading = true
      this.$post({
        url: "/app/assembly_orders/getExcelUrl",
        success: (res) => {
          let url = res.data.url7
          // window.open(url)
          window.location = url
          setTimeout(() => {
            this.btn_loading = false
          }, 1200);
        },
        tip: () => {
          setTimeout(() => {
            this.btn_loadinga = false
          }, 1200);
        },
      })
    },
    // 导入 上传file
    Uploadchange(file) {
      this.form2.file = file.raw
    },
    clearFiles() {
      this.fileList = []
      this.form2.file = ''

      this.BatchAddShow = false
    },
    //显示导入列表
    BatchAddSave() {
      this.$refs.form2.validate(valid => {
        if (valid) {
          this.btn_loading = true
          let data = new FormData();
          data.append("file", this.form2.file);
          data.append("api_id", this.$store.state.user.api_id);
          data.append("token", getToken());
          this.$post({
            url: "/app/header_field/importHeader",
            upload: true,
            data,
            success: (res) => {
              this.$message({
                message: 'Finished',
                type: 'success'
              });
              this.loading = true
              this.clearFiles()
              this.pagechage(1)

              setTimeout(() => {
                this.loading = false
                this.btn_loading = false
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.btn_loading = false
              }, 1500);
            },
          })
        }
      });
    },
  },
  created() {
    this.getconfig()
    this.getlist()
  },
}
</script>
<style>
.popper__arrow {
  border-bottom-color: #19cbbf !important;
}
.popper__arrow::after {
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #19cbbf !important;
}
.tooltip.el-tooltip__popper.is-dark {
  background: #fff;
  color: #333;
  border: 1px solid #19cbbf;
  line-height: 2;
  font-size: 13px;
}
.tooltip.el-tooltip__popper[x-placement^="right"] .popper__arrow {
  border-right-color: #19cbbf;
}
.tooltip.el-tooltip__popper[x-placement^="right"] .popper__arrow::after {
  border-right-color: #19cbbf;
}
.tooltip.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: #19cbbf;
}
.tooltip.el-tooltip__popper[x-placement^="left"] .popper__arrow::after {
  border-left-color: #19cbbf;
}
</style>
<style lang='scss' scoped>
.download {
  border-color: #19cbbf !important;
  background: #fff !important;
  color: #19cbbf !important;
}
/deep/.el-upload-dragger {
  width: 100% !important;
}
.bodybox {
  width: 100%;
  padding: 20px 0;
  background: #fff;
  border-bottom: 1px solid #dbdbdb;
  .topbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999999;
    font-size: 14px;
    p:nth-child(1) {
      color: #333333;
      font-weight: 550;
    }
    p:nth-child(2) {
      color: #666666;
      line-height: 2;
    }
  }
  /deep/.el-button--default {
    color: #19cbbf;
    background-color: #fff;
    border-color: #19cbbf;
    border-radius: 4px;
  }
  .listbox {
    width: 100%;
    margin-top: 15px;
    padding: 10px 15px;
    background: #f1f6fb;
    border-radius: 10px;
    .titbox {
      width: 100%;
      color: #999999;
      font-size: 14px;
      p:nth-child(1) {
        color: #333333;
        font-weight: 550;
      }
      p:nth-child(2) {
        padding: 10px 0 20px;
      }
    }
    .tabbox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #333333;
      font-size: 14px;
      font-weight: 550;
      padding: 8px 0;
      border-bottom: 1px solid #dbdbdb;
      p:nth-child(1) {
        width: 20%;
      }
    }
    .tablist {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-weight: 550;
      padding: 8px 0;
      line-height: 1.6;
      p:nth-child(1) {
        width: 20%;
        color: #333333;
        font-size: 14px;
      }
      p:nth-child(2) {
        width: 80%;
        color: #666666;
        font-size: 12px;
      }
    }
    /deep/.el-collapse {
      border: none;
      .el-collapse-item__header {
        background: none;
        color: #333333;
        font-size: 14px;
        font-weight: 550;
      }
      .el-collapse-item__content {
        padding-bottom: 0;
      }
      .el-collapse-item__wrap {
        border-bottom: none;
        background: none;
        .tabtit {
          margin-bottom: 20px;
          color: #999999;
          font-size: 14px;
        }
      }
    }
  }
}
.bodybox:nth-child(1) {
  border-radius: 10px 10px 0 0;
}
.bodybox:last-child {
  border-bottom: none;
  border-radius: 0 0 10px 10px;
  .upfile {
    padding: 10px 0 0;
  }
}
.indexbox {
  width: 100%;
  height: 100%;
  .searchbox {
    width: 100%;
    padding: 6px 15px;
    background: #fff;
    border-bottom: 1px solid #babdcd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .inp {
        margin-right: 10px;
      }
    }
    .right {
      /deep/.el-date-editor {
        .el-range-input:nth-of-type(1) {
          width: 200px;
        }
        .el-range-input:nth-of-type(2) {
          width: 100px;
        }
      }
    }
    /deep/.el-button {
      background-color: #d6f3e3;
      border-radius: 10px;
      color: #3b3b3b;
      border-color: #d6f3e3;
      align-self: flex-end;
      margin: 5px;
    }
  }
  .tablebox {
    width: 100%;
    padding: 20px;
  }
  .formbox {
    /deep/.el-form-item {
      margin-bottom: 30px !important;
      .el-form-item__content {
        width: 100%;
        .el-button {
          width: 100%;
          height: 48px;
          background: $color;
          border-radius: 5px;
          border-color: $color;
        }
      }
    }
  }
  .dataselect {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /deep/.el-dialog {
    min-width: 400px !important;
  }
}
</style>
